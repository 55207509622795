import * as React from "react";
import * as styles from "./SingleImage.module.scss";

const SingleImage = ({ imageSrc, imageAlt }) => {
	return (
		<div className={styles.imageContainer}>
			<img className={styles.image} src={imageSrc} alt={imageAlt} />
		</div>
	);
};

export default SingleImage;