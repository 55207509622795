import * as React from "react";
import * as styles from "./Certificates.module.scss";
import { getImage } from "../contentful/data-provider";

const Certificates = ({ certificates }) => {
	const renderItems = () => {
		return certificates.map((item, index) => {
			let currentCert = getImage(item);
			return (
				<li key={index}>
					<a className={styles.item} href={currentCert.url} target={`_blank`}>
						<div className='basisText'>{currentCert.title}</div>
						<div className='basisIcon'>
							<svg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13'>
								<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
									<g transform='translate(-623 -18)'>
										<path d='M0 0H670V50H0z'></path>
										<g stroke='#0A3867' strokeWidth='1.5' transform='rotate(45 297.415 765.821)'>
											<path strokeLinejoin='round' d='M12.713 2.154L2.317 12.55' transform='rotate(-45 7.515 7.352)'></path>
											<path
												d='M12.3753226 12.8292666L12.8297291 2.20142506 2.20096259 2.65490655'
												transform='rotate(-45 7.515 7.515)'
											></path>
										</g>
									</g>
								</g>
							</svg>
						</div>
					</a>
				</li>
			);
		});
	};

	return (
		<div className={styles.container}>
			<ul className={styles.list}>{renderItems()}</ul>
		</div>
	);
};

export default Certificates;
