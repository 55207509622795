import * as React from 'react';
import * as styles from './Columns.module.scss';

const Columns = ({ children, isCertificatesColumns }) => {
  return (
    <div className={[ 
      styles.container,
      isCertificatesColumns ? styles.isCertificatesColumns : null,
    ].join(" ")}
    >
      { children }
    </div>
  )
}

export default Columns;