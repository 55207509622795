import * as React from 'react';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import PageTitle from '../../components/PageTitle';
import Section from '../../components/Section';
import Certificates from '../../components/Certificates';
import Columns from '../../components/Columns';
import Content from '../../components/Content';
import Subtitle from '../../components/Subtitle';
import SingleImage from '../../components/SingleImage';
import {graphql} from 'gatsby';
import {renderRichText} from "gatsby-source-contentful/rich-text";

// import { getImage } from "../contentful/data-provider";
import certificateImage from "../../images/certificates.jpg"
import DoubleOffsetImage from "../../components/DoubleOffsetImage";
import {getImage} from "../../contentful/data-provider";

const ContactPage = ({data}) => {
    const {contentfulBasicPageSection, contentfulCertificateSection} = data;
    return (
        <Layout>
            <main>
                <PageHeader>
                    <PageTitle>
                        <h1>{contentfulBasicPageSection.title}</h1>
                    </PageTitle> 
                </PageHeader>
                <Section hasMaxWidth isCertificatesSection>
                    <Columns isCertificatesColumns>
                        <div>
                            <Subtitle isLargeSubtitle>
                                <h2>{contentfulBasicPageSection.subtitle}</h2>
                            </Subtitle>
                            <Content>
                                <div>
                                    {renderRichText(contentfulBasicPageSection.body)}
                                </div>
                            </Content>
                            <Certificates certificates={contentfulCertificateSection.files}/>
                        </div>
                        
                            {/* TODO:  Contentful image needed*/}
                            {/* <SingleImage imageSrc={getImage(image).url} imageAlt={getImage(image).title}/> */}
                            { contentfulBasicPageSection.images.length >= 1 &&
                                <SingleImage
                                    imageSrc={getImage(contentfulBasicPageSection.images[0]).url}
                                    imageAlt={getImage(contentfulBasicPageSection.images[0]).title}
                                />
                            }

                    </Columns>
                </Section>
            </main>
        </Layout>
    )
}

export default ContactPage;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title} | Derschlag</title>
    )
}

export const query = graphql`
    query ($locale: String!) {
        contentfulBasicPageSection(
            contentful_id: {eq: "6W97TJ3Jmc6g8vSajKzpxS"}
            node_locale: {eq: $locale}
        ) {
            ... BasicPageFields
        }
        contentfulCertificateSection(
            contentful_id: {eq: "7kpxhDjyrmB69bkFoWeLL6"}
            node_locale: {eq: $locale}
        ) {
            id
            title
            files {
                title
                url
            }
        }
    }
`;
