import * as React from "react";
import * as styles from "./DoubleOffsetImage.module.scss";
import { Parallax } from "react-scroll-parallax";
import useWindowSize from "../hooks/window-size";

const DoubleOffsetImage = ({ firstImageSrc, secondImageSrc }) => {
	const windowSize = useWindowSize();
	const paralaxValue = 7;
	const responsiveValue = windowSize.width >= 769 ? paralaxValue : 0;

	return (
		<div className={styles.container}>
			<div className={styles.col1}>
				<Parallax translateY={[0, -responsiveValue]}>
					<div className={styles.firstImageContainer}>
						<img className={styles.firstImage} src={firstImageSrc} alt={""} />
					</div>
				</Parallax>
			</div>
			<div className={styles.col2}>
				<Parallax translateY={[-0, responsiveValue]}>
					<div className={styles.secondImageContainer}>
						<img className={styles.secondImage} src={secondImageSrc} alt={""} />
					</div>
				</Parallax>
			</div>
		</div>
	);
};

export default DoubleOffsetImage;
